import { Outlet, RouteObject } from 'react-router-dom'
import { useUser } from '../queries/UseUserHook'
import ErrorNotice from '../shared/ErrorNotice'
import LoadingPage from '../shared/LoadingPage'
import AdminPage from './AdminPage'
import AdminTrainerInfo from './AdminTrainerInfo'
import AdminTrainers from './AdminTrainers'
import AdminUsers from './AdminUsers'

const AdminProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { data: user, isLoading } = useUser()

  if (isLoading) {
    return <LoadingPage />
  }

  if (!user || !user.isSuperuser) {
    return <ErrorNotice />
  }

  return <>{children}</>
}

const AdminRoutes: RouteObject[] = [
  {
    path: 'admin',
    element: (
      <AdminProtectedRoute>
        <Outlet />
      </AdminProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <AdminPage />,
      },
      {
        path: 'users',
        element: <AdminUsers />,
      },
      {
        path: 'trainers',
        element: <AdminTrainers />,
      },
      {
        path: 'trainers/:trainerUuid',
        element: <AdminTrainerInfo />,
      },
    ],
  },
]

export { AdminRoutes }
