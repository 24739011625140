import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#1E140C',
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#E0C2FF',
      // light: '#F5EBFF',
      // // dark: will be calculated from palette.secondary.main,
      // contrastText: '#47008F',
    },
  },
  typography: {
    fontFamily: 'Bricolage Grotesque, sans-serif',
  },
})

export default theme
