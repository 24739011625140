import { useQuery } from '@tanstack/react-query'
import { Position } from 'geojson'
import { getTrainersByLocation } from '../../clients/TrainerSearchClient'
import TrainerProfile from '../../models/TrainerProfile'

const useGetTrainersByLocation = (position: Position, radius?: number) => {
  return useQuery<TrainerProfile[], Error>({
    queryKey: ['trainers-by-location', position, radius],
    queryFn: async () => {
      const response = await getTrainersByLocation(position, radius)
      if (!response.ok) {
        throw new Error('Failed to fetch trainers by location')
      }
      const data = await response.json()
      return data.trainers as TrainerProfile[]
    },
  })
}

export { useGetTrainersByLocation }
