const TRAINER_VERIFICATION_API_URL = `${import.meta.env.VITE_API_BASE_URL}/trainer/verification`

async function transitionToVerified(trainerUuid: string) {
  const response = await fetch(
    `${TRAINER_VERIFICATION_API_URL}/transition/verified?trainerUuid=${trainerUuid}`,
    {
      method: 'PUT',
      credentials: 'include',
    }
  )

  return response
}

async function transitionToPending(trainerUuid: string) {
  const response = await fetch(
    `${TRAINER_VERIFICATION_API_URL}/transition/pending?trainerUuid=${trainerUuid}`,
    {
      method: 'PUT',
      credentials: 'include',
    }
  )

  return response
}

export { transitionToPending, transitionToVerified }
