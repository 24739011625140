import { AccountType } from '../models/AccountType'

const USER_API_URL = `${import.meta.env.VITE_API_BASE_URL}/user`

export async function loginUser(email: string, password: string) {
  const response = await fetch(`${USER_API_URL}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
    credentials: 'include',
  })
  return response
}

export async function logoutUser() {
  const response = await fetch(`${USER_API_URL}/logout`, {
    method: 'POST',
    credentials: 'include',
  })

  return response
}

export async function signupUser(
  accountType: AccountType,
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  phone: string,
  zipcode: string
) {
  const response = await fetch(`${USER_API_URL}/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accountType,
      firstName,
      lastName,
      email,
      password,
      phone,
      zipcode,
    }),
    credentials: 'include',
  })

  return response
}
