import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import {
  Box,
  Button,
  Card,
  CardContent,
  Skeleton,
  Typography,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAccount } from './auth/AuthProvider'
import { useUpcomingTrainerBookings } from './queries/UseBookingHook'
import LoadingPage from './shared/LoadingPage'

// Initialize the plugin
dayjs.extend(isSameOrAfter)

// Add this helper function at the top of the file, after imports
const groupLessonsByDate = (lessons: any[]) => {
  const grouped = lessons.reduce((acc, lesson) => {
    const date = dayjs(lesson.startTime).format('YYYY-MM-DD')
    if (!acc[date]) {
      acc[date] = []
    }
    acc[date].push(lesson)
    return acc
  }, {})

  // Sort lessons within each date
  Object.keys(grouped).forEach((date) => {
    grouped[date].sort(
      (a: any, b: any) =>
        dayjs(a.startTime).valueOf() - dayjs(b.startTime).valueOf()
    )
  })

  return grouped
}

// Add these helper functions after the groupLessonsByDate function
const findNearestDateWithLessons = (
  selectedDate: dayjs.Dayjs,
  groupedLessons: Record<string, any[]>
): string | null => {
  if (Object.keys(groupedLessons).length === 0) return null

  const dates = Object.keys(groupedLessons).sort()
  const selectedDateStr = selectedDate.format('YYYY-MM-DD')

  // If selected date has lessons, return it
  if (groupedLessons[selectedDateStr]) return selectedDateStr

  // Find the nearest date
  let nearestDate = dates[0]
  let minDiff = Math.abs(dayjs(dates[0]).diff(selectedDate, 'day'))

  dates.forEach((date) => {
    const diff = Math.abs(dayjs(date).diff(selectedDate, 'day'))
    if (diff < minDiff) {
      minDiff = diff
      nearestDate = date
    }
  })

  return nearestDate
}

const TrainerHome = () => {
  const navigate = useNavigate()

  // Fetch the account information from the AuthProvider
  const { account, isLoadingAccount: isLoadingAccount } = useAccount()
  const { data: upcomingBookings, isLoading: isLoadingBookings } =
    useUpcomingTrainerBookings(account?.trainer?.trainerUuid || '')

  // const progress = 60 // Simulating progress value (e.g., 60%)

  const [selectedDate, setSelectedDate] = useState(dayjs())

  const renderDayWithDot = (props: PickersDayProps<dayjs.Dayjs>) => {
    const { day, ...other } = props
    const hasBooking = upcomingBookings?.some((booking) =>
      dayjs(booking.startDatetime).isSame(day, 'day')
    )

    return (
      // hacky way to color the day if there is a booking on that day
      // refer to https://mui.com/x/react-date-pickers/date-calendar/#dynamic-data
      <PickersDay
        {...other}
        day={day}
        sx={{
          ...other.sx,
          ...(hasBooking && {
            backgroundColor: 'rgba(255, 0, 0, 0.1)',
            borderRadius: '50%',
            '&:hover': {
              backgroundColor: 'rgba(255, 0, 0, 0.2)',
            },
            '&.Mui-selected': {
              backgroundColor: 'primary.main',
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
            },
          }),
        }}
      />
    )
  }

  const scrollableBoxRef = useRef<HTMLDivElement>(null)

  if (isLoadingAccount) {
    return <LoadingPage />
  }

  const verification =
    account?.trainer?.verificationStatus !== 'profile_incomplete'

  return (
    <Box
      sx={{
        padding: '20px',
        maxWidth: '80%', // Set maximum width to 80% of viewport
        margin: '0 auto', // Center the content
        width: '100%',
        '@media (max-width: 600px)': {
          maxWidth: '100%', // Use full width on mobile
          padding: '16px',
        },
      }}
    >
      {/* Banner for completing profile */}
      {!verification && (
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            width: '100%',
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(10px)',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            padding: '12px 24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            cursor: 'pointer',
            mb: 2,
          }}
          onClick={() => navigate('/complete-profile')}
        >
          <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'red' }}>
            Please complete your profile to enable all features
          </Typography>
        </Box>
      )}

      {/* Main content with blur effect if verification is false */}
      <Box
        sx={{
          filter: !verification ? 'blur(4px)' : 'none',
          pointerEvents: !verification ? 'none' : 'auto', // Disable interactions when blurred
          opacity: !verification ? 0.6 : 1,
          transition: 'opacity 0.3s ease',
        }}
      >
        {/* Header */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">
            Hi {account?.firstName}, welcome back!
          </Typography>
          <Box display="flex" alignItems="center"></Box>
        </Box>

        {/* Calendar and Information Section */}
        <Box mt={4}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr', // Single column on mobile
                md: '380px 1fr', // Two columns on desktop (calendar width + flex)
              },
              gap: 2,
            }}
          >
            <Card
              sx={{
                boxShadow: 'none',
                border: '2px solid #e0e0e0',
                borderRadius: '16px',
                width: '100%',
              }}
            >
              <CardContent>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    value={selectedDate}
                    onChange={(newDate) => {
                      if (!newDate) return
                      setSelectedDate(newDate)

                      const groupedLessons = groupLessonsByDate(
                        upcomingBookings || []
                      )
                      const targetDate = findNearestDateWithLessons(
                        newDate,
                        groupedLessons
                      )

                      if (targetDate) {
                        const element = document.getElementById(
                          `lesson-box-${targetDate}-0`
                        )
                        if (element && scrollableBoxRef.current) {
                          const container = scrollableBoxRef.current
                          const headerHeight = 48 // Height of the sticky date header
                          const elementPosition =
                            element.offsetTop -
                            container.offsetTop -
                            headerHeight

                          container.scrollTo({
                            top: elementPosition,
                            behavior: 'smooth',
                          })
                        }
                      }
                    }}
                    slots={{
                      day: renderDayWithDot,
                    }}
                  />
                </LocalizationProvider>

                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    mt: 2,
                    backgroundColor: '#000000',
                    '&:hover': {
                      backgroundColor: '#333333',
                    },
                  }}
                  // TODO: actually make this work
                  onClick={() => navigate(`/trainer/availability`)}
                  disabled={!verification}
                >
                  Edit Availability
                </Button>
              </CardContent>
            </Card>

            <Card
              sx={{
                boxShadow: 'none',
                border: '2px solid #e0e0e0',
                borderRadius: '16px',
                width: '100%',
              }}
            >
              <CardContent>
                <Box
                  ref={scrollableBoxRef}
                  sx={{ height: '400px', overflowY: 'auto' }}
                >
                  {isLoadingBookings ? (
                    <>
                      <Box sx={{ mb: 2, p: 2, borderRadius: '12px' }}>
                        <Skeleton variant="text" width="60%" height={28} />
                        <Skeleton variant="text" width="40%" height={20} />
                        <Skeleton variant="text" width="35%" height={20} />
                      </Box>
                    </>
                  ) : upcomingBookings && upcomingBookings.length > 0 ? (
                    Object.entries(groupLessonsByDate(upcomingBookings))
                      .sort(([dateA], [dateB]) => dateA.localeCompare(dateB))
                      .map(([date, lessons]) => (
                        <Box key={date} sx={{ mb: 3 }}>
                          <Typography
                            variant="h6"
                            id={`date-header-${date}`}
                            sx={{
                              mb: 2,
                              position: 'sticky',
                              top: 0,
                              backgroundColor: 'white',
                              py: 1,
                              zIndex: 1,
                            }}
                          >
                            {dayjs(date).format('dddd, MMMM D')}
                          </Typography>
                          {(lessons as any[]).map((lesson, index) => (
                            <Box
                              key={index}
                              id={`lesson-box-${date}-${index}`}
                              sx={{
                                mb: 2,
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-end',
                                  mr: 2,
                                  minWidth: '100px',
                                }}
                              >
                                <Typography variant="h6" fontWeight="bold">
                                  {dayjs(lesson.startTime).format('h:mm A')}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                >
                                  {dayjs(lesson.startTime).diff(
                                    dayjs(),
                                    'days'
                                  ) === 0
                                    ? 'today'
                                    : `${dayjs(lesson.startTime).diff(dayjs(), 'days')} days left`}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  flex: 1,
                                  p: 2,
                                  border: '2px solid #e0e0e0',
                                  borderRadius: '12px',
                                }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  fontWeight="bold"
                                  gutterBottom
                                >
                                  {lesson.learnerFirstName}{' '}
                                  {lesson.learnerLastName}
                                </Typography>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    gap: 2,
                                    flexWrap: 'wrap',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 0.5,
                                    }}
                                  >
                                    <LocationOnIcon
                                      fontSize="small"
                                      color="action"
                                    />
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      {lesson.bowlingCenterName}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 0.5,
                                    }}
                                  >
                                    <CalendarTodayIcon
                                      fontSize="small"
                                      color="action"
                                    />
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      {dayjs(lesson.startTime).format(
                                        'MMM D, YYYY'
                                      )}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 0.5,
                                    }}
                                  >
                                    <AccessTimeIcon
                                      fontSize="small"
                                      color="action"
                                    />
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      {dayjs(lesson.startTime).format('h:mm A')}{' '}
                                      - {dayjs(lesson.endTime).format('h:mm A')}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      ))
                  ) : (
                    <Typography color="text.secondary">
                      No upcoming lessons scheduled
                    </Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Box>

      {/* Progress Bar & Achievements */}
      {/* <Box mt={4} textAlign="center">
        <Typography variant="h6">Your Progress</Typography>
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress variant="determinate" value={progress} />
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ mt: 1, px: 2 }}
          >
            {['Bronze', 'Silver', 'Gold', 'Platinum'].map((label, index) => (
              <Typography
                key={label}
                variant="caption"
                color={index * 25 <= progress ? 'textPrimary' : 'textSecondary'}
              >
                {label}
              </Typography>
            ))}
          </Box>
        </Box> */}

      {/* Achievement section */}
      {/* <Box mt={4}>
        <EmojiEventsIcon sx={{ fontSize: '80px', color: '#9c9c9c' }} />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Complete 1 more lesson to achieve Silver Level
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: 2 }}>
          You are currently on <strong>Bronze Level</strong>. Achieve silver
          level to unlock perks.
        </Typography>
        <Button
          variant="contained"
          sx={{ backgroundColor: '#000000', color: '#fff' }}
        >
          Learn More
        </Button>
      </Box>
      </Box> */}

      {/* Trainer Information Section */}
      {/* <Box mt={4}>
        <Typography variant="h6">Your Profile Information</Typography>
        <Card
          sx={{
            mt: 4,
            boxShadow: 'none',
            border: '2px solid #e0e0e0',
            borderRadius: '16px',
          }}
        >
          <CardContent>
            <Typography variant="h4" gutterBottom>
              {account?.firstName} {account?.lastName}
            </Typography>
            <Typography variant="body1">
              Certifications:{' '}
              {account?.trainer?.certifications?.join(', ') ||
                'No Certifications'}
            </Typography>
            <Typography variant="body1">
              Bowling Styles:{' '}
              {account?.trainer?.bowlingStyles?.join(', ') ||
                'No Bowling Styles'}
            </Typography>
            <Typography variant="body1" mt={1}>
              Hourly Rate: ${account?.trainer?.hourlyRate || 'N/A'}
            </Typography>
            <Button
              variant="contained"
              sx={{ mt: 2, backgroundColor: '#000000' }}
              onClick={() =>
                navigate(`/trainer/${account?.trainer?.trainerUuid}/profile`)
              }
            >
              View Full Profile
            </Button>
          </CardContent>
        </Card>
      </Box> */}
    </Box>
  )
}

export default TrainerHome
