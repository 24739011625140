import { useQuery } from '@tanstack/react-query'
import { getTrainerProfile } from '../../clients/TrainerClient'
import TrainerProfile from '../../models/TrainerProfile'

export const useTrainerProfile = (trainerUuid: string) => {
  return useQuery<TrainerProfile, Error>({
    queryKey: ['trainerProfile', trainerUuid],
    queryFn: async () => {
      const response = await getTrainerProfile(trainerUuid)
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      return data.trainerProfile
    },
    enabled: !!trainerUuid,
  })
}
