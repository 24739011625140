import { Box, Card, CardMedia, Typography } from '@mui/material'

const CLOUD_FRONT_LADINGPAGE = import.meta.env.VITE_CLOUDFRONT_DOMAIN

const HowItWorks = () => {
  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: { xs: 2, sm: 4, md: 6, lg: 8 },
        marginBottom: { xs: 6, sm: 10, md: 12, lg: 14 },
        marginLeft: { xs: 4, sm: 10, md: 16, lg: 20 },
        marginRight: { xs: 4, sm: 10, md: 16, lg: 20 },
      }}
    >
      {/* Title */}
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontFamily: 'Bricolage Grotesque, sans-serif',
          fontSize: { xs: '5vw', sm: '3.5vw', md: 32, lg: 36 },
          fontWeight: 'medium',
          marginBottom: { xs: 4, sm: 6, md: 8, lg: 10 },
        }}
      >
        How It Works
      </Typography>

      {/* Steps */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: 5, sm: 8, md: 10, lg: 12 },
        }}
      >
        {/* Step 1 + Step 2 Combined */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'flex-start', sm: 'center' },
            gap: { xs: 3, sm: 5, md: 6, lg: 8 },
            position: 'relative',
          }}
        >
          {/* Images for Step 1 + Step 2 */}
          <Box
            sx={{
              position: 'relative',
              width: { xs: '100%', sm: '50%', md: '40%' },
              textAlign: 'center',
              maxWidth: { xs: '250px', sm: '400px', md: '450px', lg: '500px' },
              margin: '0 auto',
            }}
          >
            <Card
              elevation={3}
              sx={{
                borderRadius: 4,
                padding: 2,
                position: 'relative',
                left: { xs: '-50px', sm: '-60px', md: '-40px', lg: '-90px' },
                zIndex: 1,
              }}
            >
              <CardMedia
                component="img"
                height="100"
                image={`${CLOUD_FRONT_LADINGPAGE}/landing-page/trainerProfile.svg`}
                alt="Coach Profile"
                sx={{
                  width: '100%',
                  height: 'auto',
                }}
              />
            </Card>
            <Card
              elevation={3}
              sx={{
                borderRadius: 2,
                padding: 1.5,
                position: 'absolute',
                bottom: '-30px',
                left: { xs: '50px', sm: '70px', md: '80px', lg: '100px' },
                zIndex: 2,
              }}
            >
              <CardMedia
                component="img"
                height="100"
                image={`${CLOUD_FRONT_LADINGPAGE}/landing-page/BookLesson.svg`}
                alt="Book Lesson"
                sx={{
                  width: '100%',
                  maxWidth: {
                    xs: '200px',
                    sm: 'none',
                    md: '300px',
                    lg: '350px',
                  },
                  height: 'auto',
                }}
              />
            </Card>
          </Box>

          {/* Texts for Step 1 + Step 2 */}
          <Box
            sx={{
              textAlign: 'left',
              width: { xs: '100%', sm: '50%', md: '60%' },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: { xs: 2, sm: 4, md: 6, lg: 8 },
            }}
          >
            {/* Step 1 */}
            <Box sx={{ marginBottom: { xs: 4, sm: 8, md: 10, lg: 12 } }}>
              <Box
                component="span"
                sx={{
                  backgroundColor: '#F5FAF8',
                  color: '#28a745',
                  border: '1px solid #28A745',
                  borderRadius: '50%',
                  width: { xs: '7vw', sm: '6vw', md: '5vw', lg: '4vw' },
                  height: { xs: '7vw', sm: '6vw', md: '5vw', lg: '4vw' },
                  maxWidth: 35,
                  maxHeight: 35,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'semi-bold',
                  marginBottom: 2,
                  fontSize: { xs: '4vw', sm: '2.5vw', md: 22, lg: 26 },
                }}
              >
                1
              </Box>
              <Typography
                variant="h5"
                sx={{
                  fontFamily: 'Bricolage Grotesque, sans-serif',
                  fontWeight: 'semi-bold',
                  fontSize: { xs: '4vw', sm: '2.5vw', md: 22, lg: 26 },
                  marginBottom: 1,
                }}
              >
                Find Your Coach
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: 'Bricolage Grotesque, sans-serif',
                  fontSize: { xs: '3vw', sm: '2vw', md: 16, lg: 20 },
                  color: '#9E9E9E',
                }}
              >
                Browse profiles of certified coaches to find the best match for
                your goals.
              </Typography>
            </Box>

            {/* Step 2 */}
            <Box>
              <Box
                component="span"
                sx={{
                  backgroundColor: '#F5FAF8',
                  color: '#28a745',
                  border: '1px solid #28A745',
                  borderRadius: '50%',
                  width: { xs: '7vw', sm: '6vw', md: '5vw', lg: '4vw' },
                  height: { xs: '7vw', sm: '6vw', md: '5vw', lg: '4vw' },
                  maxWidth: 35,
                  maxHeight: 35,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'semi-bold',
                  marginBottom: 2,
                  fontSize: { xs: '4vw', sm: '2.5vw', md: 22, lg: 26 },
                }}
              >
                2
              </Box>
              <Typography
                variant="h5"
                sx={{
                  fontFamily: 'Bricolage Grotesque, sans-serif',
                  fontWeight: 'semi-bold',
                  fontSize: { xs: '4vw', sm: '2.5vw', md: 22, lg: 26 },
                  marginBottom: 1,
                }}
              >
                Book a Lesson
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: 'Bricolage Grotesque, sans-serif',
                  fontSize: { xs: '3vw', sm: '2vw', md: 16, lg: 20 },
                  color: '#9E9E9E',
                }}
              >
                Use our user-friendly platform to book lessons at your
                convenience.
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Step 3 */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'flex-start', sm: 'center' },
            gap: { xs: 3, sm: 5, md: 6, lg: 8 },
          }}
        >
          <Box
            sx={{
              width: { xs: '100%', sm: '50%', md: '40%' },
              textAlign: 'center',
              maxWidth: { xs: '250px', sm: '400px', md: '450px', lg: '500px' },
              margin: '0 auto',
            }}
          >
            <Card
              elevation={3}
              sx={{
                borderRadius: 3,
                padding: 2,
              }}
            >
              <CardMedia
                component="img"
                height="100"
                image={`${CLOUD_FRONT_LADINGPAGE}/landing-page/EventBooked.svg`}
                alt="Event Booked"
                sx={{
                  width: '100%',
                  height: 'auto',
                }}
              />
            </Card>
          </Box>

          <Box
            sx={{
              textAlign: 'left',
              width: { xs: '100%', sm: '50%', md: '60%' },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 2,
            }}
          >
            <Box
              component="span"
              sx={{
                backgroundColor: '#F5FAF8',
                color: '#28a745',
                borderRadius: '50%',
                width: { xs: '7vw', sm: '6vw', md: '5vw', lg: '4vw' },
                height: { xs: '7vw', sm: '6vw', md: '5vw', lg: '4vw' },
                maxWidth: 35,
                maxHeight: 35,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'semi-bold',
                border: '1px solid #28A745',
                fontSize: { xs: '4vw', sm: '2.5vw', md: 22, lg: 26 },
              }}
            >
              3
            </Box>
            <Typography
              variant="h5"
              sx={{
                fontFamily: 'Bricolage Grotesque, sans-serif',
                fontWeight: 'semi-bold',
                fontSize: { xs: '4vw', sm: '2.5vw', md: 22, lg: 26 },
              }}
            >
              Improve & Have Fun
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontFamily: 'Bricolage Grotesque, sans-serif',
                fontSize: { xs: '3vw', sm: '2vw', md: 16, lg: 20 },
                color: '#9E9E9E',
              }}
            >
              Enjoy personalized coaching and track your progress with your
              coach.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default HowItWorks
