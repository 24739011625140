const USER_API_URL = `${import.meta.env.VITE_API_BASE_URL}/user`

async function getUser() {
  const response = await fetch(`${USER_API_URL}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })

  return response
}

async function changePassword({
  currentPassword,
  newPassword,
}: {
  currentPassword: string
  newPassword: string
}) {
  const response = await fetch(`${USER_API_URL}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      currentPassword,
      newPassword,
    }),
  })

  return response
}

async function sendVerificationEmail() {
  const response = await fetch(`${USER_API_URL}/send-verification-email`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })

  return response
}

async function verifyEmail(token: string, expiresAt: string) {
  const response = await fetch(`${USER_API_URL}/verify-email`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      token,
      expiresAt,
    }),
  })

  return response
}

export { changePassword, getUser, sendVerificationEmail, verifyEmail }
