import LoadingButton from '@mui/lab/LoadingButton'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { transitionToVerified } from '../../clients/TrainerVerificationClient'
import { useTrainerProfile } from '../queries/UseTrainerProfileHook'
import LoadingPage from '../shared/LoadingPage'
import { useAdminGetTrainer } from './hooks/AdminUseTrainersHook'

const AdminTrainerInfo = () => {
  const [isVerifying, setIsVerifying] = useState(false)
  const { trainerUuid } = useParams()
  const {
    data: trainerProfile,
    isLoading: isLoadingTrainerProfile,
    refetch,
  } = useTrainerProfile(trainerUuid ?? '')
  const { data: trainer, isLoading: isLoadingTrainer } = useAdminGetTrainer(
    trainerUuid ?? ''
  )

  if (isLoadingTrainerProfile || isLoadingTrainer) {
    return <LoadingPage />
  }

  if (!trainerProfile || !trainer) {
    return <div>Trainer not found</div>
  }

  async function handleTrainerVerify() {
    if (!trainerProfile) {
      return
    }

    setIsVerifying(true)
    await transitionToVerified(trainerProfile.trainerUuid)
    setIsVerifying(false)

    refetch()
  }

  return (
    <div>
      <h1>{trainerProfile.trainerUuid}</h1>
      <h2>
        Name: {trainerProfile.firstName} {trainerProfile.lastName}
      </h2>
      <h2>Can train? {trainer.canTrain ? 'Yes' : 'No'}</h2>
      <h2>Verification status: {trainer.verificationStatus}</h2>
      <LoadingButton
        variant="contained"
        onClick={handleTrainerVerify}
        loading={isVerifying}
      >
        Verify
      </LoadingButton>
    </div>
  )
}

export default AdminTrainerInfo
