import { Box } from '@mui/material'
import Footer from './Footer'
import Navbar from './Navbar'

const Layout = ({ children }: React.PropsWithChildren) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      maxWidth="100vw"
      overflow="hidden"
    >
      <Box>
        <Navbar />
      </Box>

      <Box
        flexGrow={1}
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        width="100%"
        overflow="hidden"
      >
        {children}
      </Box>

      <Box mt="auto">
        <Footer />
      </Box>
    </Box>
  )
}

export default Layout
