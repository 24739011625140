import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ScheduleIcon from '@mui/icons-material/Schedule'
import SchoolIcon from '@mui/icons-material/School'
import StarIcon from '@mui/icons-material/Star'
import VerifiedIcon from '@mui/icons-material/Verified'

import { SearchBox } from '@mapbox/search-js-react'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { styled } from '@mui/system'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LearnerHome from '../../components/LearnerHome'
import TrainerHome from '../../components/TrainerHome'
import { useAccount } from '../auth/AuthProvider'
import { useGetPopularTrainers } from '../queries/UseTrainersHook'
import LoadingPage from '../shared/LoadingPage'
import HowItWorks from './HowItWorks'

const CLOUD_FRONT_LADINGPAGE = import.meta.env.VITE_CLOUDFRONT_DOMAIN
const accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN ?? ''

const HeroSection = styled(Box)({
  position: 'relative',
  minHeight: '100vh',
  backgroundImage: `url(${CLOUD_FRONT_LADINGPAGE}/landing-page/backgroundImage.jpg)`,
  backgroundSize: 'cover',
  backgroundPosition: 'top',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  width: '100%',
  overflow: 'hidden',
  '@media (max-width: 600px)': {
    paddingTop: '10vh', // Reduced padding for mobile
  },
})

const TrainerCardContainer = styled(Box)({
  display: 'flex',
  overflowX: 'auto',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  gap: 16,
  padding: '16px 0',
  position: 'relative',
  width: '100%',
  maxWidth: '100vw',
})

const TrainerCard = styled(Box)({
  width: 300,
  minWidth: 280,
  maxWidth: '90vw',
  height: 450,
  backgroundColor: 'white',
  boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
  borderRadius: 8,
  overflow: 'hidden',
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
  cursor: 'pointer',
  position: 'relative',
  flexShrink: 0,
})

const BlurOverlayLeft = styled(Box)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  width: 50,
  zIndex: 2,
  background:
    'linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
})

const BlurOverlayRight = styled(Box)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  width: 50,
  zIndex: 2,
  background:
    'linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
})

const SearchButton = styled(Button)({
  borderRadius: '50px',
  padding: '10px 20px',
  backgroundColor: '#1E140C',
  color: 'white',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#1C86EE',
  },
})

const FeatureCard = styled(Box)({
  padding: '20px',
  backgroundColor: 'white',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  borderRadius: 12,
  textAlign: 'center',
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
})

const ChipStyled = styled(Chip)({
  fontSize: '0.875rem',
  fontWeight: 'bold',
  borderRadius: '8px',
  px: 1,
  py: 0.5,
  maxWidth: '100px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const EmpowerSection = () => (
  <Box sx={{ py: 8, backgroundColor: '#f7f7f7', textAlign: 'center' }}>
    <Container maxWidth="lg">
      <Typography
        variant="subtitle1"
        sx={{
          textTransform: 'uppercase',
          fontWeight: 'bold',
          color: '#8EB695',
          letterSpacing: 1,
          mb: 1,
        }}
      >
        Our promises
      </Typography>
      <Typography
        variant="h4"
        fontWeight="bold"
        sx={{
          mb: 2,
          color: '#333',
          fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
        }}
      >
        Empowering bowlers, one strike at a time
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        sx={{
          maxWidth: '600px',
          margin: '0 auto',
          fontSize: { xs: '0.875rem', sm: '1rem' },
          lineHeight: 1.6,
          color: '#666',
          mb: 6,
        }}
      >
        At StrikeTutor, we make professional bowling coaching accessible to
        everyone, helping bowlers at every level unlock their full potential
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6} md={6}>
          <FeatureCard>
            <EmojiEventsIcon sx={{ fontSize: 40, color: '#8EB695', mb: 2 }} />
            <Typography variant="h6" fontWeight="bold" className="mb-2">
              Expert certified coaches
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Experienced coaches provide personalized training to match your
              skill level and goals
            </Typography>
          </FeatureCard>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FeatureCard>
            <ScheduleIcon sx={{ fontSize: 40, color: '#8EB695', mb: 2 }} />
            <Typography variant="h6" fontWeight="bold" className="mb-2">
              Convenient scheduling
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Easily book, track, and reschedule lessons with just a few clicks
              on our user-friendly platform
            </Typography>
          </FeatureCard>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FeatureCard>
            <SchoolIcon sx={{ fontSize: 40, color: '#8EB695', mb: 2 }} />
            <Typography variant="h6" fontWeight="bold" className="mb-2">
              Structured learning
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Our step-by-step coaching helps you improve consistently,
              regardless of your skill levels
            </Typography>
          </FeatureCard>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FeatureCard>
            <LocationOnIcon sx={{ fontSize: 40, color: '#8EB695', mb: 2 }} />
            <Typography variant="h6" fontWeight="bold" className="mb-2">
              Community connections
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Find nearby bowling centers and connect with other bowlers in your
              area through StrikeTutor
            </Typography>
          </FeatureCard>
        </Grid>
      </Grid>
    </Container>
  </Box>
)

const Home = () => {
  const navigate = useNavigate()
  const scrollRef = useRef<HTMLDivElement>(null)
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(true)
  const { account, isLoadingAccount } = useAccount()
  const { data: popularTrainers, isLoading } = useGetPopularTrainers()
  const [searchInput, setSearchInput] = useState('')
  const [searchCoordinates, setSearchCoordinates] = useState<
    [number, number] | null
  >(null)

  const checkScrollPosition = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current
      setCanScrollLeft(scrollLeft > 0)
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth)
    }
  }

  const scroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const scrollAmount = direction === 'left' ? -300 : 300
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', checkScrollPosition)
    }
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', checkScrollPosition)
      }
    }
  }, [scrollRef.current])

  const handleSearch = () => {
    if (searchCoordinates) {
      navigate(
        `/trainers?search=${searchInput}&lat=${searchCoordinates[0]}&lng=${searchCoordinates[1]}`
      )
    }
  }

  if (isLoadingAccount) {
    return <LoadingPage />
  }

  if (account?.trainer) {
    return <TrainerHome />
  } else if (account?.learner) {
    return <LearnerHome />
  }

  return (
    <Box>
      <HeroSection>
        <Container
          maxWidth="md"
          sx={{
            position: 'relative',
            zIndex: 2,
            textAlign: 'left',
            color: 'white',
            paddingTop: '20vh',
            '@media (max-width: 600px)': {
              paddingTop: '10vh',
            },
          }}
        >
          <Typography
            variant="h3"
            fontWeight="bold"
            gutterBottom
            sx={{
              fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
            }}
          >
            Unlock your&nbsp;
            <Typography
              component="span"
              sx={{
                color: '#C3E3D3',
                display: 'inline',
                fontSize: 'inherit',
              }}
            >
              bowling potential
            </Typography>{' '}
            with expert coaches
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mb: 4,
              fontSize: { xs: '0.875rem', sm: '1rem' },
              lineHeight: { xs: 1.4, sm: 1.6 },
            }}
          >
            StrikeTutor connects you with certified bowling coaches to improve
            your game and have fun
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 2,
              width: '100%',
              mt: { xs: 2, sm: 4 },
            }}
          >
            <Box sx={{ width: { xs: '100%', sm: '60%', mb: '8px' } }}>
              {/* @ts-ignore */}
              <SearchBox
                accessToken={accessToken}
                options={{
                  country: 'us',
                  limit: 5,
                  // @ts-ignore
                  types: ['poi', 'postcode'],
                  poi_category: 'bowling_alley',
                }}
                value={searchInput}
                onChange={setSearchInput}
                onRetrieve={(res) => {
                  if (res.features.length > 0) {
                    const coords = res.features[0].geometry.coordinates
                    setSearchCoordinates([coords[0], coords[1]])
                    setSearchInput(res.features[0].properties.name_preferred)
                  }
                }}
                placeholder="Search by zipcode or bowling center"
                theme={{
                  variables: {
                    boxShadow: 'none',
                    border: '1px solid #c9c9c9',
                    borderRadius: '16px',
                  },
                }}
              />
            </Box>
            {searchCoordinates && (
              <SearchButton
                variant="contained"
                onClick={handleSearch}
                sx={{
                  width: { xs: '100%', sm: 'auto' },
                }}
              >
                Find coach near me
              </SearchButton>
            )}
          </Box>
        </Container>
      </HeroSection>
      <Box sx={{ py: 4, backgroundColor: 'white', textAlign: 'center' }}>
        <Typography variant="h6" fontWeight="medium" gutterBottom>
          Partnering with certified coaches from
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 4,
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            src={`${CLOUD_FRONT_LADINGPAGE}/landing-page/PBALogo.svg`}
            alt="PBA Logo"
            sx={{ width: { xs: 140, sm: 200, md: 260 }, height: 'auto' }}
          />
          <Box
            component="img"
            src={`${CLOUD_FRONT_LADINGPAGE}/landing-page/USBCLogo.svg`}
            alt="Bowlero Logo"
            sx={{ width: { xs: 140, sm: 200, md: 260 }, height: 'auto' }}
          />
        </Box>
      </Box>
      <Container maxWidth="lg" sx={{ py: 6, position: 'relative' }}>
        <BlurOverlayLeft />
        <IconButton
          onClick={() => scroll('left')}
          sx={{
            position: 'absolute',
            left: -40,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 3,
            opacity: canScrollLeft ? 1 : 0.3,
            pointerEvents: canScrollLeft ? 'auto' : 'none',
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>

        <TrainerCardContainer ref={scrollRef}>
          {isLoading ? (
            <CircularProgress />
          ) : popularTrainers && popularTrainers.length > 0 ? (
            popularTrainers.map((trainer) => (
              <TrainerCard
                key={trainer.trainerUuid}
                onClick={() => {
                  navigate(`/trainer/${trainer.trainerUuid}/profile`)
                }}
              >
                <Box
                  component="img"
                  src={trainer.profilePictureUrl}
                  alt={trainer.firstName}
                  sx={{ width: '100%', height: 200, objectFit: 'cover' }}
                />
                <Box sx={{ p: 2 }}>
                  <Typography variant="h6" fontWeight="bold">
                    {trainer.lastName}
                  </Typography>

                  <Box display="flex" gap={1} flexWrap="wrap" mb={1}>
                    {trainer.bowlingStyles?.map((type: string) => (
                      <ChipStyled
                        key={type}
                        label={type}
                        sx={{
                          bgcolor:
                            type === 'Two-handed'
                              ? '#AFFFE7'
                              : type === 'Stroker'
                                ? '#E2ECF6'
                                : type === 'Cranker'
                                  ? '#F6E2E2'
                                  : '#E2F6F1',
                        }}
                      />
                    ))}
                  </Box>

                  <Box display="flex" alignItems="center" gap={0.5} mb={1}>
                    <LocationOnIcon fontSize="small" />
                    {trainer.bowlingCenter.name}
                  </Box>
                  <Box display="flex" alignItems="center" gap={0.5} mb={1}>
                    <StarIcon sx={{ color: '#ffdf00' }} fontSize="small" />
                    {trainer.reviewCount > 0
                      ? `${trainer.averageRating.substring(0, 3)} (${trainer.reviewCount} review${trainer.reviewCount > 1 ? 's' : ''})`
                      : 'No ratings'}
                  </Box>
                  <Box display="flex" alignItems="center" gap={0.5}>
                    <VerifiedIcon fontSize="small" sx={{ color: 'green' }} />
                    {trainer.certifications}
                  </Box>
                  <Box display="flex" alignItems="center" gap={0.5} mt={1}>
                    <LocalAtmIcon fontSize="small" />${trainer.hourlyRate} per
                    session
                  </Box>
                </Box>
              </TrainerCard>
            ))
          ) : (
            <Typography
              variant="h6"
              color="textSecondary"
              align="center"
            ></Typography>
          )}
        </TrainerCardContainer>

        <BlurOverlayRight />
        <IconButton
          onClick={() => scroll('right')}
          sx={{
            position: 'absolute',
            right: -40,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 3,
            opacity: canScrollRight ? 1 : 0.3,
            pointerEvents: canScrollRight ? 'auto' : 'none',
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Container>
      <EmpowerSection />
      <HowItWorks />
    </Box>
  )
}

export default Home
