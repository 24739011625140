import {
  Box,
  Container,
  IconButton,
  List,
  ListItem,
  styled,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'

const FooterLink = styled(Link)({
  color: '#606060',
  '&:hover': {
    textDecoration: 'underline',
  },
})

const Footer = () => {
  return (
    <Box
      component="footer"
      py={1.5}
      borderTop="1px solid"
      borderColor="grey.200"
    >
      <Container maxWidth="lg">
        <Box display="flex" flexDirection="row" justifyContent="space-around">
          <Box width="25%">
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              color="textPrimary"
            >
              About
            </Typography>
            <List disablePadding>
              <ListItem disableGutters>
                <FooterLink to="/who-we-are">Who are we?</FooterLink>
              </ListItem>
              <ListItem disableGutters>
                <FooterLink to="/our-values">Our values</FooterLink>
              </ListItem>
              <ListItem disableGutters>
                <FooterLink to="/terms-and-conditions">
                  Terms & conditions
                </FooterLink>
              </ListItem>
              <ListItem disableGutters>
                <FooterLink to="/privacy-policy">Privacy policy</FooterLink>
              </ListItem>
              <ListItem disableGutters>
                <FooterLink to="/cookies-policy">Cookies policy</FooterLink>
              </ListItem>
            </List>
          </Box>

          <Box width="25%">
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              color="textPrimary"
            >
              Resources
            </Typography>
            <List disablePadding>
              <ListItem disableGutters>
                <FooterLink to="/help-center">Help center</FooterLink>
              </ListItem>
              <ListItem disableGutters>
                <FooterLink to="/contact-us">Contact us</FooterLink>
              </ListItem>
            </List>
          </Box>

          <Box width="25%">
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              color="textPrimary"
            >
              Follow us
            </Typography>
            <Box display="flex" flexDirection="row">
              <IconButton
                component="a"
                href="https://www.instagram.com/striketutor"
                target="_blank"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png"
                  alt="Instagram"
                  style={{ width: 14, height: 14 }}
                />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          m={2}
          fontSize="0.75rem"
        >
          © 2024 StrikeTutor. All rights reserved.
        </Typography>
      </Container>
    </Box>
  )
}

export default Footer
