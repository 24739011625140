import {
  AccountCircleOutlined,
  ArrowDropDown,
  LogoutOutlined,
  PhotoCameraFrontOutlined,
} from '@mui/icons-material'
import {
  Avatar,
  Button,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import StriketutorLogo from '../../assets/striketutor_logo.svg'
import StriketutorText from '../../assets/striketutor_text.svg'
import { useAccount } from '../auth/AuthProvider'

const Navbar = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { account, isLoadingAccount, doLogout } = useAccount()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isDropdownOpen = Boolean(anchorEl)

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    handleDropdownClose()
  }, [location])

  return (
    <nav className="flex h-16 sm:h-20 justify-between items-center border-b border-gray-200 w-full">
      <NavLink to="/" className="flex items-center space-x-1 sm:space-x-2 px-8">
        <img
          src={StriketutorLogo}
          alt="Striketutor Logo"
          className="h-5 w-5 sm:h-8 sm:w-8"
        />
        <img
          src={StriketutorText}
          alt="Striketutor Text"
          className="h-5 sm:h-8"
        />
      </NavLink>
      <div className="flex gap-3 sm:gap-8 h-full items-center">
        {isLoadingAccount ? (
          <Skeleton variant="text" width={60} height={32} />
        ) : account ? (
          <div className="flex items-center h-full">
            <Button
              onClick={handleAvatarClick}
              disableRipple
              sx={{ height: '100%' }}
            >
              <Avatar
                src={account.profilePictureUrl || undefined}
                alt="Account"
                sx={{
                  width: { xs: 24, sm: 40 },
                  height: { xs: 24, sm: 40 },
                  marginRight: 1,
                }}
              />
              <ArrowDropDown />
            </Button>
            {anchorEl ? (
              <Menu
                sx={{
                  '& .MuiMenu-list': {
                    padding: 0,
                  },
                  '& .MuiMenuItem-root': {
                    height: '56px',
                  },
                }}
                open={isDropdownOpen}
                anchorEl={anchorEl}
                onClick={handleDropdownClose}
                onClose={handleDropdownClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                elevation={4}
              >
                {account.trainer ? (
                  <MenuItem
                    onClick={() => {
                      handleDropdownClose()
                      navigate(
                        `/trainer/${account.trainer?.trainerUuid}/profile`
                      )
                    }}
                    sx={{ borderBottom: '1px solid #e0e0e0' }}
                  >
                    <PhotoCameraFrontOutlined sx={{ marginRight: 1 }} />
                    Profile
                  </MenuItem>
                ) : null}
                <MenuItem
                  onClick={() => {
                    handleDropdownClose()
                    navigate('/account')
                  }}
                  sx={{ borderBottom: '1px solid #e0e0e0' }}
                >
                  <AccountCircleOutlined sx={{ marginRight: 1 }} />
                  Account
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleDropdownClose()
                    doLogout()
                  }}
                >
                  <LogoutOutlined sx={{ color: '#be4142', marginRight: 1 }} />
                  <Typography color="#be4142">Logout</Typography>
                </MenuItem>
              </Menu>
            ) : null}
          </div>
        ) : (
          <NavLink
            to="/signup"
            className="text-xs sm:text-base whitespace-nowrap mr-4"
          >
            Sign Up / Login
          </NavLink>
        )}
      </div>
    </nav>
  )
}

export default Navbar
