import { ThemeProvider } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { ErrorBoundary } from 'react-error-boundary'
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom'
import { AdminRoutes } from './components/admin/AdminRoutes'
import AuthenticatedRoute from './components/auth/AuthenticatedRoute'
import { AuthProvider } from './components/auth/AuthProvider'
import Home from './components/landingPage/Home'
import LearnerHome from './components/LearnerHome'
import ErrorNotice from './components/shared/ErrorNotice'
import Layout from './components/shared/Layout'
import LoadingPage from './components/shared/LoadingPage'
import VerifyEmail from './components/VerifyEmail'
import './styles.css'
import theme from './theme'

// Lazy load all components
const RecoverPassword = React.lazy(() => import('./components/RecoverPassword'))
const ContactUs = React.lazy(() => import('./footerInfo/ContactUs'))
const CookiesPolicy = React.lazy(() => import('./footerInfo/CookiesPolicy'))
const HelpCenter = React.lazy(() => import('./footerInfo/HelpCenter'))
const OurValues = React.lazy(() => import('./footerInfo/OurValues'))
const PrivacyPolicy = React.lazy(() => import('./footerInfo/PrivacyPolicy'))
const TermsAndConditions = React.lazy(
  () => import('./footerInfo/TermsAndConditions')
)
const WhoWeAre = React.lazy(() => import('./footerInfo/WhoWeAre'))

// Lazy load other main components
const Login = React.lazy(() => import('./components/Login'))
const SignUp = React.lazy(() => import('./components/SignUp'))
const TrainerProfile = React.lazy(() => import('./components/TrainerProfile'))
const AccountPage = React.lazy(
  () => import('./components/accountManagement/AccountManagement')
)
const CompleteProfile = React.lazy(() => import('./components/CompleteProfile'))
const FindTrainer = React.lazy(() => import('./components/FindTrainer'))
const CalendarForLearner = React.lazy(
  () => import('./components/CalendarForLearner')
)
const BookLesson = React.lazy(() => import('./components/BookLesson'))
const CalendarTrainerV1 = React.lazy(
  () => import('./components/CalendarTrainerV1')
)
const Checkout = React.lazy(() => import('./components/Checkout'))

const queryClient = new QueryClient()

const router = createBrowserRouter([
  {
    element: (
      <Layout>
        <Outlet />
      </Layout>
    ),
    errorElement: (
      <Layout>
        <ErrorNotice />
      </Layout>
    ),
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/trainer/availability',
        element: <CalendarTrainerV1 />,
      },
      {
        path: 'signup',
        element: <SignUp />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'recover-password',
        element: <RecoverPassword />,
      },
      {
        path: 'complete-profile',
        element: <CompleteProfile />,
      },
      {
        path: 'bowling-trainers',
        element: <FindTrainer />,
      },
      {
        path: 'trainer/:trainerUuid/profile',
        element: <TrainerProfile />,
      },
      {
        path: 'who-we-are',
        element: <WhoWeAre />,
      },
      {
        path: 'our-values',
        element: <OurValues />,
      },
      {
        path: 'terms-and-conditions',
        element: <TermsAndConditions />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />,
      },
      {
        path: 'help-center',
        element: <HelpCenter />,
      },
      {
        path: 'contact-us',
        element: <ContactUs />,
      },
      {
        path: 'cookies-policy',
        element: <CookiesPolicy />,
      },
      {
        path: 'account',
        element: (
          <AuthenticatedRoute>
            <AccountPage />
          </AuthenticatedRoute>
        ),
      },
      {
        path: 'session/selection',
        element: <CalendarForLearner />,
      },
      {
        path: 'trainer/:trainerUuid/book-lesson',
        element: (
          <AuthenticatedRoute>
            <BookLesson />
          </AuthenticatedRoute>
        ),
      },
      { path: 'trainers', element: <LearnerHome /> },
      { path: 'verify-email', element: <VerifyEmail /> },
      ...AdminRoutes,
    ],
  },
  { element: <Checkout />, path: 'checkout' },
])

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary FallbackComponent={ErrorNotice}>
          <React.Suspense fallback={<LoadingPage />}>
            <AuthProvider>
              <RouterProvider router={router} />
            </AuthProvider>
          </React.Suspense>
        </ErrorBoundary>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>
)
