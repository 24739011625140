const BOOKING_API_URL = `${import.meta.env.VITE_API_BASE_URL}/booking`

async function getPreviousAndUpcomingLearnerBookings(learnerUuid: string) {
  const response = await fetch(
    `${BOOKING_API_URL}/learner/${learnerUuid}/previous-and-upcoming`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }
  )

  return response
}

async function getUpcomingTrainerBookings(trainerUuid: string) {
  const response = await fetch(
    `${BOOKING_API_URL}/upcoming/trainer/${trainerUuid}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }
  )

  return response
}

export { getPreviousAndUpcomingLearnerBookings, getUpcomingTrainerBookings }
