import { useQuery } from '@tanstack/react-query'
import {
  getTrainerForAdmin,
  getTrainersForAdmin,
} from '../clients/AdminTrainerClient'
import TrainerForAdminView from '../models/TrainerForAdminView'

// Hook to fetch all trainers
const useAdminGetTrainers = () => {
  return useQuery<TrainerForAdminView[], Error>({
    queryKey: ['admin/trainers'],
    queryFn: async () => {
      const response = await getTrainersForAdmin()
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      return data.trainers as TrainerForAdminView[]
    },
  })
}

const useAdminGetTrainer = (trainerUuid: string) => {
  return useQuery<TrainerForAdminView, Error>({
    queryKey: ['admin/trainer', trainerUuid],
    queryFn: async () => {
      const response = await getTrainerForAdmin(trainerUuid)
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      return data.trainer as TrainerForAdminView
    },
  })
}

export { useAdminGetTrainer, useAdminGetTrainers }
