import { Position } from 'geojson'

const TRAINER_SEARCH_API_URL = `${import.meta.env.VITE_API_BASE_URL}/trainer/search`

async function getTrainersByLocation(position: Position, radius?: number) {
  let url = `${TRAINER_SEARCH_API_URL}/location?longitude=${position[0]}&latitude=${position[1]}`
  if (radius) {
    url += `&radius=${radius}`
  }
  const response = await fetch(url, {
    method: 'GET',
    credentials: 'include',
  })
  return response
}

export { getTrainersByLocation }
