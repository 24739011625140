const ErrorNotice = () => {
  return (
    <div
      role="alert"
      className="flex flex-col items-center justify-center grow m-8"
    >
      <h1 className="text-4xl font-bold mb-4">Oops!</h1>
      <p className="text-lg mb-4">Something went wrong.</p>
      <a href="/" className="text-blue-500">
        Go back to the home page
      </a>
    </div>
  )
}

export default ErrorNotice
