import { useQuery } from '@tanstack/react-query'
import {
  getPreviousAndUpcomingLearnerBookings,
  getUpcomingTrainerBookings,
} from '../../clients/BookingClient'
import Booking from '../../models/Booking'

interface PreviousAndUpcomingLearnerBookings {
  previousBooking: Booking
  upcomingBookings: Booking[]
}

const usePreviousAndUpcomingLearnerBookings = (learnerUuid: string) => {
  return useQuery<PreviousAndUpcomingLearnerBookings, Error>({
    queryKey: ['previousAndUpcomingLearnerBookings', learnerUuid],
    queryFn: async () => {
      const response = await getPreviousAndUpcomingLearnerBookings(learnerUuid)
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      return {
        previousBooking: data.previousBooking,
        upcomingBookings: data.upcomingBookings,
      } as PreviousAndUpcomingLearnerBookings
    },
    retry: false, // TODO: add more robust retry logic
    enabled: !!learnerUuid,
  })
}

const useUpcomingTrainerBookings = (trainerUuid: string) => {
  return useQuery<Booking[], Error>({
    queryKey: ['upcomingTrainerBookings', trainerUuid],
    queryFn: async () => {
      const response = await getUpcomingTrainerBookings(trainerUuid)
      if (response.status === 404) {
        return [] as Booking[]
      }

      const data = await response.json()
      return data.bookings as Booking[]
    },
  })
}

export { usePreviousAndUpcomingLearnerBookings, useUpcomingTrainerBookings }
