import { Navigate, useLocation } from 'react-router-dom'
import { useAccount } from './AuthProvider'

const AuthenticatedRoute = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation()

  const { account, isLoadingAccount } = useAccount()

  if (isLoadingAccount) {
    return null
  }

  if (!account) {
    return <Navigate to={`/login?redirectUrl=${location.pathname}`} />
  }

  return children
}

export default AuthenticatedRoute
