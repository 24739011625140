import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { verifyEmail } from '../clients/UserClient'
import { useAccount } from './auth/AuthProvider'
import LoadingPage from './shared/LoadingPage'

const VerifyEmail = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)

  const { account, isLoadingAccount } = useAccount()

  const token = queryParams.get('tk')
  const expiresAt = queryParams.get('ex')

  useEffect(() => {
    const handleVerifyEmail = async () => {
      if (token && expiresAt && !isLoadingAccount) {
        const response = await verifyEmail(token, expiresAt)

        let state = {}
        if (response.ok) {
          state = { emailVerified: 'verified' }
        } else if (response.status === 410) {
          state = { emailVerified: 'expired' }
        } else if (response.status === 404) {
          state = { emailVerified: 'notFound' }
        } else {
          state = { emailVerified: 'failed' }
        }

        if (account) {
          navigate('/account', { state })
        } else {
          navigate('/login', { state })
        }
      }
    }

    handleVerifyEmail()
  }, [token, expiresAt, account, isLoadingAccount])

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <LoadingPage />
    </Box>
  )
}

export default VerifyEmail
