import { useQuery } from '@tanstack/react-query'
import { getUser } from '../../clients/UserClient'
import User from '../../models/User'

export const useUser = () => {
  return useQuery<User, Error>({
    queryKey: ['user'],
    queryFn: async () => {
      const response = await getUser()
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      return data.user as User
    },
  })
}
