import { Coordinates } from '../models/Coordinates'
import { Availability } from '../models/TrainerAvailability'

const TRAINER_API_URL = `${import.meta.env.VITE_API_BASE_URL}/trainer`

async function getTrainerProfile(trainerUuid: string) {
  const response = await fetch(
    `${TRAINER_API_URL}/profile?trainerUuid=${trainerUuid}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  )
  return response
}

async function getTrainer(trainerUuid: string) {
  const response = await fetch(
    `${TRAINER_API_URL}?trainerUuid=${trainerUuid}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  )
  return response
}

async function getPopularTrainers() {
  const response = await fetch(`${TRAINER_API_URL}/popular`, {
    method: 'GET',
    credentials: 'include',
  })
  return response
}

async function updateProfile({
  accountUuid,
  bowlingStyles,
  certifications,
  pbaNumber,
  usbcNumber,
  about,
  hourlyRate,
  bowlingCenterCoordinates,
  bowlingCenterName,
}: UpdateProfileRequest) {
  const response = await fetch(`${TRAINER_API_URL}/update-profile`, {
    method: 'PUT',
    body: JSON.stringify({
      accountUuid,
      bowlingStyles,
      certifications,
      pbaNumber,
      usbcNumber,
      about,
      hourlyRate,
      bowlingCenterCoordinates,
      bowlingCenterName,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })

  return response
}

async function getTrainerAvailableTrainings(trainerUuid: string) {
  const response = await fetch(
    `${TRAINER_API_URL}/${trainerUuid}/available-trainings`,
    {
      method: 'GET',
      credentials: 'include',
    }
  )

  return response
}

async function updateTrainerAvailability(availability: Availability[]) {
  const response = await fetch(`${TRAINER_API_URL}/availability`, {
    method: 'POST',
    body: JSON.stringify({ availability }),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })

  return response
}

async function getTrainerAvailability() {
  const response = await fetch(`${TRAINER_API_URL}/availability`, {
    method: 'GET',
    credentials: 'include',
  })
  return response
}

async function deleteTrainerAvailability() {
  const response = await fetch(`${TRAINER_API_URL}/availability`, {
    method: 'DELETE',
    credentials: 'include',
  })

  return response
}

async function getOpenTrainings(trainerUuid: string) {
  const response = await fetch(
    `${TRAINER_API_URL}/${trainerUuid}/open-trainings`,
    {
      method: 'GET',
      credentials: 'include',
    }
  )

  return response
}

async function startBankAccountSetup() {
  const response = await fetch(`${TRAINER_API_URL}/billing/setup`, {
    method: 'POST',
    credentials: 'include',
  })
  return response
}

async function addPayoutAccount(paymentMethodId: string) {
  const response = await fetch(`${TRAINER_API_URL}/billing/account/payout`, {
    method: 'POST',
    body: JSON.stringify({ paymentMethodId }),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })
  return response
}

async function getPayoutAccounts() {
  const response = await fetch(`${TRAINER_API_URL}/billing/accounts`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Cache-Control': 'no-cache',
    },
  })
  return response
}

async function createStripeAccountSession() {
  const response = await fetch(`${TRAINER_API_URL}/billing/connect`, {
    method: 'POST',
    credentials: 'include',
  })
  return response
}

async function createStripeAccount(token: string) {
  const response = await fetch(`${TRAINER_API_URL}/billing/account`, {
    method: 'POST',
    body: JSON.stringify({ token }),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response
}

export interface UpdateProfileRequest {
  accountUuid?: string
  bowlingStyles?: string[]
  certifications?: string[]
  pbaNumber?: string
  usbcNumber?: string
  about?: string
  hourlyRate?: string
  bowlingCenterCoordinates?: Coordinates
  bowlingCenterName?: string
}

export {
  addPayoutAccount,
  createStripeAccount,
  createStripeAccountSession,
  deleteTrainerAvailability,
  getOpenTrainings,
  getPayoutAccounts,
  getPopularTrainers,
  getTrainer,
  getTrainerAvailability,
  getTrainerAvailableTrainings,
  getTrainerProfile,
  startBankAccountSetup,
  updateProfile,
  updateTrainerAvailability,
}
