import UpdateAccountRequest from './request/UpdateAccountRequest'

const ACCOUNT_API_URL = `${import.meta.env.VITE_API_BASE_URL}/account`

async function getAccount() {
  const response = await fetch(`${ACCOUNT_API_URL}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })

  return response
}

async function updateAccount({
  firstName,
  lastName,
  phone,
  zipcode,
}: UpdateAccountRequest) {
  const response = await fetch(`${ACCOUNT_API_URL}`, {
    method: 'PUT',
    body: JSON.stringify({ firstName, lastName, phone, zipcode }),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })

  return response
}

async function uploadProfilePicture(file: File) {
  const formData = new FormData()
  formData.append('profilePicture', file)

  const response = await fetch(`${ACCOUNT_API_URL}/upload/profile-picture`, {
    method: 'POST',
    body: formData,
    credentials: 'include',
  })

  return response
}

async function getPaymentMethods() {
  const response = await fetch(`${ACCOUNT_API_URL}/payment-methods`, {
    method: 'GET',
    credentials: 'include',
  })

  return response
}

export { getAccount, getPaymentMethods, updateAccount, uploadProfilePicture }
