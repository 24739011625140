import { MoreHoriz } from '@mui/icons-material'
import { Button, Menu, Paper, TableSortLabel } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LoadingPage from '../shared/LoadingPage'
import { useAdminGetTrainers } from './hooks/AdminUseTrainersHook'
import TrainerForAdminView from './models/TrainerForAdminView'

type Order = 'asc' | 'desc'

const AdminTrainers = () => {
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [orderBy, setOrderBy] = useState<keyof TrainerForAdminView>('createdAt')
  const [order, setOrder] = useState<'asc' | 'desc'>('desc')
  const { data: trainers, isLoading } = useAdminGetTrainers()
  const [actionMenuOpen, setActionMenuOpen] = useState('')
  const [actionMenuAnchor, setActionMenuAnchor] =
    useState<HTMLButtonElement | null>(null)

  const visibleRows = useMemo(
    () =>
      [...(trainers || [])]
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, trainers]
  )

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (a: TrainerForAdminView, b: TrainerForAdminView) => number {
    return order === 'desc'
      ? (a, b) =>
          descendingComparator(a, b, orderBy as keyof TrainerForAdminView)
      : (a, b) =>
          -descendingComparator(a, b, orderBy as keyof TrainerForAdminView)
  }

  const handleRequestSort = (
    _: React.MouseEvent,
    property: keyof TrainerForAdminView
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  if (isLoading) {
    return <LoadingPage />
  }

  if (!trainers) {
    return <div>No trainers found</div>
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Trainer UUID</TableCell>
              <TableCell
                sortDirection={orderBy === 'createdAt' ? order : false}
                align="right"
              >
                <TableSortLabel
                  active={orderBy === 'createdAt'}
                  direction={orderBy === 'createdAt' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'createdAt')}
                >
                  Date Created
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={orderBy === 'canTrain' ? order : false}
                align="right"
              >
                <TableSortLabel
                  active={orderBy === 'canTrain'}
                  direction={orderBy === 'canTrain' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'canTrain')}
                >
                  Can Train
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={orderBy === 'verificationStatus' ? order : false}
                align="right"
              >
                <TableSortLabel
                  active={orderBy === 'verificationStatus'}
                  direction={orderBy === 'verificationStatus' ? order : 'asc'}
                  onClick={(event) =>
                    handleRequestSort(event, 'verificationStatus')
                  }
                >
                  Verification Status
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((trainer) => (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={trainer.trainerUuid}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{trainer.trainerUuid}</TableCell>
                <TableCell align="right">
                  {new Date(trainer.createdAt).toLocaleDateString()}
                </TableCell>
                <TableCell align="right">
                  {trainer.canTrain ? 'Yes' : 'No'}
                </TableCell>
                <TableCell align="right">
                  {trainer.verificationStatus}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={(event) => {
                      setActionMenuOpen(trainer.trainerUuid)
                      setActionMenuAnchor(event.currentTarget)
                    }}
                  >
                    <MoreHoriz />
                  </Button>
                  <Menu
                    open={actionMenuOpen === trainer.trainerUuid}
                    onClose={() => setActionMenuOpen('')}
                    anchorEl={actionMenuAnchor}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setActionMenuOpen('')
                        setActionMenuAnchor(null)
                        navigate(`/admin/trainers/${trainer.trainerUuid}`)
                      }}
                    >
                      View Trainer
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={trainers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default AdminTrainers
